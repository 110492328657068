
import { ref } from "vue";
import {useStore} from "vuex";
import {updateProfile,resetPassword} from "@/api/profile";
import {ElMessage} from "element-plus";
import {useRouter} from "vue-router";

export default {
  setup() {
    const store = useStore();
    const user = store.state.user;
    const activeTab = ref('profile');
    const router = useRouter();
    let avatar = null;
    if(user.profile.avatar) {
      avatar = process.env.VUE_APP_BASE_API + user.profile.avatar;
    }
    const profileRules = {
      email: [
        {
          type: "email",
          message: "'请输入正确的邮箱地址",
          trigger: ["blur", "change"]
        }
      ],
      phone: [
        {
          pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          message: "请输入正确的手机号码",
          trigger: "blur"
        }
      ]
    };

    const resetPasswordRules = {
      oldPassword: [
        { required: true, message: "旧密码不能为空", trigger: "blur" }
      ],
      newPassword: [
        { required: true, message: "新密码不能为空", trigger: "blur" },
        { min: 6, max: 20, message: "密码长度在6到20个字符之间", trigger: "blur" }
      ],
      confirmPassword: [
        { required: true, message: "确认密码不能为空", trigger: "blur" }
      ]
    }

    const userForm = ref(
        {
          username: user.username,
          nickname: '',
          phone: '',
          email: '',
        }
    );
    const passwordForm = ref(
        {
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        }
    );

    const profileForm = ref();
    const submitProfile = () => {
      profileForm.value.validate(valid => {
        if(valid) {
          if (!userForm.value.nickname && !userForm.value.phone && !userForm.value.email) {
            return;
          }

          updateProfile(userForm.value).then((res: any) => {
            if (res.errCode) {
              ElMessage.error(res.detail);
              return;
            }
            ElMessage.success("修改资料成功!");
            location.reload();
          });
        }
      });
    };


    const pwdForm = ref();
    const submitPassword = () => {
      pwdForm.value.validate(valid => {
        if(valid) {
          if(passwordForm.value.newPassword !== passwordForm.value.confirmPassword) {
            ElMessage.error('两次密码输入不一致!');
            return;
          }
          resetPassword(passwordForm.value).then((res: any) => {
            if(res.errCode) {
              ElMessage.error(res.detail);
              return;
            }
            ElMessage.success('重置密码成功,请使用新密码登录');
            store.dispatch('Logout').then(() => {
              router.push('/login');
            }).catch(() => {
              Promise.reject();
            });
          });
        }
      });
    };

    const close = () => {
      router.back();
    };

    const openCropper = () => {
      console.log('打开裁剪框');
    }


    return {
      user,
      avatar,
      activeTab,
      profileRules,
      userForm,
      passwordForm,
      submitProfile,
      submitPassword,
      close,
      resetPasswordRules,
      pwdForm,
      profileForm,
      openCropper,

    }
  },
  components: {
  }
}
